(function(win, $) {
  'use strict';
  var fp = (win.fp = typeof win.fp !== 'undefined' ? win.fp : {}),
    home = (fp.home = typeof fp.home !== 'undefined' ? fp.home : {});

  var getScreenSize = function() {
    var pixelDensity = window.devicePixelRatio || 1;
    var width = window.innerWidth * pixelDensity;

      if (width >= 2400) {
          return 'xlarge';
      }
      if (width >= 1400) {
          return 'large';
      }
      if (width >= 1000) {
          return 'medium';
      }
      if (width >= 600) {
          return 'small';
      }
      return 'xsmall';
  };

  fp.home = (function() {
    var windowHeight;
    var initialized = false;

    return {
      init: function() {
        $('.homepage-link').addClass('selected');

        function setHeroSize(initialLoad) {
          var height = $(window).height();
          var headerHeight = $('header').outerHeight();
          var fixedFooterHeight = $('#fixed-footer').outerHeight();
          var maxHeight = height - fixedFooterHeight - headerHeight;

          $('.slideshow').css('height', maxHeight);

          if (initialLoad === true) {
            $('body').removeClass('fade-home-contents');
            initialLoad = false;
            // This, along with some CSS, helps smooth out scrolling on small screens
            setTimeout(function () {
                $('body').addClass('hero-set');
            }, 1000);
          }
        }

        setHeroSize(true);

        // Execute code each time window size changes
        $(window).resize(setHeroSize);
      },

      setBgImage: function(imgInfo) {
        var screenSize = getScreenSize();
        var filteredImages = imgInfo.filter(function(img) {
          return img.assetSize === screenSize;
        });
        if (filteredImages.length === 0) {
          return;
        }
        
        var randomNumber = Math.floor(Math.random() * filteredImages.length);
        var randomBackgroundImage = filteredImages[randomNumber];

        var slideshowEl = document.getElementsByClassName(
          "slideshow"
        )[0];
        slideshowEl.style.backgroundImage =
          "url('" + randomBackgroundImage.imagePath + "')";
        if (randomBackgroundImage.altText) {
            slideshowEl.setAttribute("aria-label", randomBackgroundImage.altText);
            slideshowEl.setAttribute('role', 'img');
        }
      }
    };
  })();
})(window, $);